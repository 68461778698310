import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { UserRoleService } from '../services/userrole.service';

@Injectable({
  providedIn: 'root'
})
export class UserRoleGuard implements CanActivate {
  constructor(private readonly userRoleService: UserRoleService) {}

  // only allow if we have a valid session
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.userRoleService.ready$.pipe(
      filter(ready => !!ready),
      map(() => {
        let allowed = true;
        if (next?.data?.moduleMinUserRole) {
          switch(next.data.moduleMinUserRole) {
            case 'owner':
              allowed = this.userRoleService.hasPrivilegeOwner();
              break;
            case 'admin':
              allowed = this.userRoleService.hasPrivilegeAdmin();
              break;
            case 'write':
              allowed = this.userRoleService.hasPrivilegeWrite();
              break;
            case 'read':
              allowed = true;
              break;
          }
        }
        return allowed;
      })
    )
    
  }
}